<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>账号管理</p>
			<i>/</i>
			<p @click="jumpPage">角色列表</p>
			<i>/</i>
			<p class="active">角色权限维护</p>
		</nav>

		<div class="page_table">

			<div class="tree_wrap">
				<el-tree :data="jurisdictionList" show-checkbox node-key="id" :default-expanded-keys="openArr"
					:default-checked-keys="selectShowArr" :props="defaultProps" @check="nodeCheck">
				</el-tree>
			</div>

		</div>

		<div class="page_edit_preservation">
			<p class="p1" @click="upPage">返回</p>
			<p class="p2" @click="editForm()">保存</p>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'rolePowerRun',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				jurisdictionList: [], // 权限列表
				defaultProps: {
					children: 'second',
					label: 'menu_name'
				},
				openArr: [], // 展开数组
				selectShowArr: [], // 已选数组展示
				selectServeData: [], // 选中数组提交给后台
				btn: false,  // 是否有选择操作
				havePowerData: [], // 获取已有权限数据
				selectArr: [], // 后端返回已选数组
			}
		},
		components: {

		},
		created() {
			// 获取权限菜单
			this.getRolePower();
		},
		methods: {
			// 获取权限菜单
			getRolePower() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}menu/list`,
					data: {}
				}).then(res => {
					if (res.code == '0') {
						this.jurisdictionList = res.data;
						
						// 获取已选中的角色权限
						this.getHavePower();
					}
				})
			},
			
			// 获取已选中的角色权限
			getHavePower(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/rolepermission`,
					data: {
						role_id: this.$route.query.id,
					}
				}).then(res => {
					if (res.code == '0') {
						
						this.selectArr = res.data.menu_ids.split(",");
						console.log(this.selectArr)
						// 后端会把自选了一个的父级id也返回，在显示时就会表现为当前分类所有全选
						let arr1 = [];
						
						this.selectArr.forEach( checkid => {
							this.jurisdictionList.forEach( item1 => {
								if(item1.second && item1.second.length > 0){
									item1.second.forEach( child => {
										if(checkid == child.id){
											arr1.push(child.id);
											// 在循环里直接赋值，有问题，显示数组菜单不认
											// this.selectShowArr.push(child.id)
										}
									})
								}
							})
						})
						
						// 在循环里直接赋值，有问题，显示数组菜单不认
						this.selectShowArr = this.selectShowArr.concat(arr1)
						this.openArr = this.selectShowArr;
						
					}
				})
			},

			// 当前节点选中状态发生改变
			nodeCheck(data, elObj) {
				this.btn = true;
				this.selectServeData = [];
				this.selectServeData = this.selectServeData.concat(elObj.checkedKeys, elObj.halfCheckedKeys);
			},

			editForm() {
				if (this.selectServeData.length == 0 && this.selectArr.length == 0) {
					this.$alert('请选择当前账号需要配置的权限,或点击返回键取消此次编辑', '子账号权限配置', {
						confirmButtonText: '确定',
						callback: action => {console.log(action)}
					});
					return;
				}

				if (this.btn) {
					if (this.selectServeData.length == 0) {
						this.$alert('请选择当前账号需要配置的权限,或点击返回键取消此次编辑', '子账号权限配置', {
							confirmButtonText: '确定',
							callback: action => {console.log(action)}
						});
						return;
					}
				} else {
					this.selectServeData = this.selectArr;
				}

				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}admin/setpermission`,
					data: {
						role_id: this.$route.query.id,
						menu_ids: this.selectServeData.join(','),
					},
				}).then(res => {

					if (res.code == '0') {
						this.$message({
							message: '处理成功',
							type: 'success'
						});

						this.$router.go(-1);

					} else {
						this.$message.error(`${res.msg}`);
					}
				})

			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},
			
			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	.tree_wrap {
		padding: 24px 0;
	}
	
	.page_edit_preservation{
		margin-top: 16px;
		padding: 24px;
	}
	
</style>
